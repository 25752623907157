<template>
	<div id="root">
		<fulfillment-filter />

		<data-card-list />

		<div class="pb-2" style="flex: 1;">
			<VueSlickCarousel v-bind="settings" class="remaining-area pt-1">
				<div class="container-fluid p-0 pl-1 pr-1 h-100">
					<div class="row h-100 align-items-stretch">
						<div class="col-6 h-50 p-1">
							<RevenueLineBar />
						</div>

						<div class="col-6 h-50 p-1">
							<GPLineBar />
						</div>
						
						<div class="col-6 h-50 p-1">
							<FTEAndPTEHeadCount />
						</div>

						<div class="col-6 h-50 p-1">
							<FTEAndPTECost />
						</div>
					</div>
				</div>


				<div class="container-fluid p-0 pl-1 pr-1 h-100">
					<div class="row h-100 align-items-stretch">
						<div class="col-6 h-50 p-1">
							<RevenuePie />
						</div>

						<div class="col-6 h-50 p-1">
							<GPPie />
						</div>

						<div class="col-6 h-50 p-1">
							<VASPie />
						</div>
					</div>
				</div>
			</VueSlickCarousel>
		</div>
	</div>
</template>

<script>
	import VueSlickCarousel from "vue-slick-carousel";
	import { computed, onMounted, onUnmounted, ref } from "@vue/composition-api";
	import store from "@/store";

	import FulfillmentFilter from "./components/FulfillmentFilter.vue";
	import DataCardList from "./components/data-cards/accrual/DataCardList.vue";

	import RevenueLineBar from "./components/charts/accrual/RevenueLineBar.vue";
	import RevenuePie from './components/charts/accrual/RevenuePie.vue'

	import GPLineBar from "./components/charts/accrual/GPLineBar.vue";
	import FTEAndPTEHeadCount from "./components/charts/accrual/FTEAndPTEHeadCount.vue";
	import FTEAndPTECost from "./components/charts/accrual/FTEAndPTECost.vue";
	import GPPie from "./components/charts/accrual/GPPie.vue";

	import VASPie from "./components/charts/accrual/VASPie.vue";

	export default {
		components: {
			VueSlickCarousel,

			FulfillmentFilter,
			DataCardList,

			RevenueLineBar,
			RevenuePie,

			GPLineBar,
			FTEAndPTEHeadCount,
			FTEAndPTECost,
			GPPie,
			VASPie
		},
		setup() {
			return {
				settings: {
					arrows: true,
					dots: true,
					centerMode: true,
					centerPadding: "50px",
					focusOnSelect: true,
					infinite: true,
					slidesToShow: 1,
					speed: 500,
				},
			};
		},
	};
</script>

<style scoped>
	#root {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.remaining-area {
		flex: 1;
	}
	.padding-chart {
		padding: 0.5rem;
	}
</style>
